body, html, * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

nav {
  position: fixed;
  display: flex;
  top: 20px;
  width: 100%;
  gap: 20px;
  justify-content: right;
  .hamburgermenu {
    margin-right: 20px;
    display: flex;
    background-color: #fff;
    width: 64px;
    height: 64px;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 20px;
    font-size: 24px;
    color: #2c3e50;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: #2c3e50;
      color: #fff;
    }
  }
  ul {
    margin-left: 20px;
    max-width: 320px;
    flex: 1;
    background-color: #fff;
    list-style-type: none;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.15);
    overflow: hidden;
    a {
      text-decoration: none;
      color: #2c3e50;
      display: block;
      transition: all .1s;
      &:hover {
        background-color: #2c3e50;
        color: #fff;
      }
    }
    li {
      font-family: 'Montserrat', sans-serif;
      padding: 20px;
    }
  }
}

header {
  text-align: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  .header img {
    object-fit: cover;
    object-position: 0 -250px;
    height: 600px;
  }
} 

main {
  padding: 40px 0px 0 0;
  section {
    margin-bottom: 50px;
    padding: 0 40px;
    max-width: 960px;
    margin: 0 auto 40px auto;
    p {
      text-align: center;
      font-family: 'Open Sans', sans-serif;
      color: #7f8c8d;
    }
  }
  .intro {
    text-align: center;
    max-width: 480px;

    img {
      margin-bottom: 10px;
      width: 48px;
    }

    p {
      margin-bottom: 20px;
    }
  }
  .strategiecall {
    text-decoration: none;
    color: #2c3e50;
    font-family: 'Montserrat', sans-serif;
    border: 1px solid #2c3e50;
    padding: 15px 20px;
    border-radius: 5px;
    display: block;
    text-align: center;
    width: 100%;
    font-size: 14px;
    transition: all .3s;
    &:hover {
      background-color: #2c3e50;
      color: #fff;
    }
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
      h2 {
        font-size: 24px;
        text-align: center;
        color: #fff;
      }
      margin-bottom: 50px;
  }
  .banner.skills {
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),url("./assets/skills.JPG");
    background-size: cover;
    background-position: center;
  }
  .banner.succes {
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),url("./assets/succes.jpg");
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
  }
  .banner.ervaringen {
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),url("./assets/ervaringen.JPG");
    background-size: cover;
    background-position: 25% 50%;
    margin-bottom: 40px;
  }
  section.succes {
    padding: 0 20px;
    margin-bottom: 20px;
  }
  h1, h2, h3 {
    font-family: 'Montserrat', sans-serif;
    color: #2c3e50;
  }
  h1 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 10px;
  }
  h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 20px;
  }
  .title {
    margin-bottom: 50px;
  }
  .projects {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 20px;
    text-align: center;
    .project {
      flex: 33.3%;
      text-align: center;
      padding: 50px;
      border-radius: 5px;
      h3 {
        margin-bottom: 5px;
        color: #fff;
        font-size: 16px;
      }
      p {
        color:#fff;
        font-size: 12px;
      }
    }
    .project.instagram {
      background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://images.unsplash.com/photo-1579869847557-1f67382cc158?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2534&q=80);
      background-size: cover;
      background-position: center;
    }
    .project.modsi {
      background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://images.unsplash.com/photo-1575285113814-f770cb8c796e?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8bXVzaWMlMjBhcnRpc3R8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60);
      background-size: cover;
      background-position: center;
    }
    .project.salesmissie {
      background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./assets/salesmissie.jpg');
      background-size: cover;
      background-position: center;
    }
    .project.younghustlers {
      background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://images.unsplash.com/photo-1518365050014-70fe7232897f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cm9ja2V0fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=800&q=60);
      background-size: cover;
      background-position: center;
    }
    .project.degrootehond {
      background:linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url('./assets/degrootehond.jpg');
      background-size: cover;
      background-position: 25% 25%;
      flex: 100%
    }
    .more {
      width: 100%;
      text-align: center;
    }
  }
  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 25px;
    justify-content:space-between;
    .item {
      flex: 30%;
      margin-bottom: 20px;
      text-align: center;
      border-radius: 5px;
      img {
        height: 48px;
        margin-bottom: 10px;
      }
      h3 {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .ervaringen {
    display: flex;
    gap: 20px;
    .ervaring {
      flex: 100%;
      text-align: center;
      margin-bottom: 20px;
      img {
        width: 48px;
        margin-bottom: 10px;
      }
      .emma {
        width: 56px;
      }
      h3 {
        margin-bottom: 10px;
      }
      p {
        margin-bottom: 10px;
      }
      .stars {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 5px;
        .star {
          img {
            width: 24px;
          }
        }
      }
    }
  }
}

footer {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 40px;
  p {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #7f8c8d;
    margin-bottom: 20px;
  }
   
  .socials {
    display: flex;
    gap: 10px;
    justify-content: center;
    a {
      color: #2c3e50;
      
      .tiktok {
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 960px) {
  header {
    height: auto;
  .header {
    img {
      object-position: 0 -100px;
      height: 500px;
    }
  }
}
.item {
  flex: 40%!important;
}
.hamburgermenu {
  &:hover {
    background-color: #fff!important;
    color: #2c3e50!important;
  }
}
}

@media only screen and (max-width: 600px) {
  .header {
    img {
      object-position: 0 -50px!important;
      height: 250px!important;
    }
  }
  .items {
    flex-direction: column!important;
  }

  main .projects {
    flex-direction: column;
  }

  .ervaringen {
    flex-direction: column;
  }
}